
import { defineComponent } from 'vue'
import ArrowBack from '@/assets/svg/arrow-back.svg?inline'
import PhotoMock from '@/assets/svg/photo-zaglushka.svg?inline'
import CopyInstructor from '@/assets/svg/copy-instructor.svg?inline'
import Edit from '@/assets/svg/edit-blue.svg?inline'
import Delete from '@/assets/svg/delete-blue.svg?inline'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import Picture from '@/assets/test/picture.svg?inline'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
// import SortArrow from '@/assets/svg/sort-arrow.svg?inline'

export default defineComponent({
  name: 'Instructor',
  data () {
    return {
      isModalDelete: false,
      instructorData: {},
      instructorId: '',
      errorInstructor: null,
      errorInstructors: null,
      generateCode: '',
      errorInviteCode: null
    }
  },
  components: {
    ArrowBack,
    PhotoMock,
    Edit,
    Delete,
    DefaultPopup,
    Picture,
    AlertBlock,
    CopyInstructor
    // SortArrow
  },
  mounted () {
    this.instructorId = this.$route.query.instructor
    this.getInstructor(this.instructorId)
  },
  methods: {
    openDeletePoup (id) {
      this.idInstructor = id
      this.isModalDelete = true
    },
    handleCloseModalDelete () {
      this.isModalDelete = false
      this.errorInstructors = null
    },
    deleteInstructor () {
      this.$store.dispatch('instructors/deleteInstructor', this.idInstructor).then(
        () => {
          this.idInstructor = null
          this.$router.push('/admin/instructors')
        },
        (error) => {
          this.errorInstructors = error.response.data
        }
      )
    },
    editInstructor (id) {
      this.$router.push('/admin/create-instructors?edit=true&instructor=' + id)
    },
    getInstructor (id) {
      this.$store.dispatch('instructors/getInstructor', id).then(
        (res) => {
          this.instructorData = res.data
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        }
      )
    },
    generateInviteCode (data) {
      data = []
      data.push(this.instructorId)
      this.$store.dispatch('instructors/generateInviteCode', data).then(
        (res) => {
          this.generateCode = res.data
        },
        (error) => {
          this.errorInviteCode = error.response.data.message
        }
      )
    },
    copyText () {
      const textToCopy = this.generateCode.code
      const textarea = document.createElement('textarea')
      textarea.value = textToCopy
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
    }
  }
})
